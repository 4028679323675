<div class="bubble" (click)="onToggle()" [ngClass]="{'visible': isVisible}">
  <app-svg shape="feedback" width=20 height=20></app-svg>
</div>
<div class="form-container" [ngClass]="{'open': isOpen, 'visible': isVisible}">
  <div class="form">
    <div class="form-header">
      Feedback
      <span (click)="onToggle()">x</span>
    </div>
    <div class="form-logo">
      <app-panomnom-image src={{constants.DEFAULT_PANOMNOM_LOGO_URL}} [height]=100 alt="Panomnom Logo"></app-panomnom-image>
    </div>
    <div class="form-body">
      <p>We appreciate hearing from you and will review your comments carefully.</p>
      <textarea placeholder="Tell us more..." [(ngModel)]="feedbackValue"></textarea>
      <div class="form-button" (click)="onSubmitFeedback()" [ngClass]="{'disabled': isFeedbackSubmitted}">Submit</div>
      <p [ngClass]="{'invisible': !isFeedbackSubmitted}">Thank you for taking time to provide feedback!</p>
    </div>
  </div>
</div>