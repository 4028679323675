import { environment } from 'src/environments/environment';

import { enableProdMode } from '@angular/core';

import { bootstrapApplication } from '@angular/platform-browser';
import { init as sentryInit } from '@sentry/angular';
import { AppComponent } from 'src/app/app.component';
import { appConfig } from 'src/app/app.config';

sentryInit({
  dsn: "https://9c49c70cf79f486384b79aa2948806cc@o47291.ingest.sentry.io/5418933",
});

if (environment.production) {
  enableProdMode();
}

function bootstrap() {
  bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
};


if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}
