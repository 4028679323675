import { Routes } from '@angular/router';
import { RoutePlaceholder } from './app-routing-helper';

export const routes: Routes = [
  { path: `:${RoutePlaceholder.LOCALE}`, loadComponent: () => import('src/app/pages/page-home/page-home.component').then(m => m.PageHomeComponent) },
  { path: `:${RoutePlaceholder.LOCALE}/about-us`, loadComponent: () => import('src/app/pages/page-about-us/page-about-us.component').then(m => m.PageAboutUsComponent) },
  { path: `:${RoutePlaceholder.LOCALE}/blogs`, loadComponent: () => import('src/app/pages/page-blogs/page-blogs.component').then(m => m.PageBlogsComponent) },
  { path: `:${RoutePlaceholder.LOCALE}/blog/:${RoutePlaceholder.BLOG_SLUG}`, loadComponent: () => import('src/app/pages/page-blog/page-blog.component').then(m => m.PageBlogComponent) },
  { path: `:${RoutePlaceholder.LOCALE}/categories`, loadComponent: () => import('src/app/pages/page-categories/page-categories.component').then(m => m.PageCategoriesComponent) },
  { path: `:${RoutePlaceholder.LOCALE}/privacy-policy`, loadComponent: () => import('src/app/pages/page-privacy-policy/page-privacy-policy.component').then(m => m.PagePrivacyPolicyComponent) },
  { path: `:${RoutePlaceholder.LOCALE}/recipe/:${RoutePlaceholder.RECIPE_SLUG}`, loadComponent: () => import('src/app/pages/page-recipe/page-recipe.component').then(m => m.PageRecipeComponent) },
  { path: `:${RoutePlaceholder.LOCALE}/search`, loadComponent: () => import('src/app/pages/page-search/page-search.component').then(m => m.PageSearchComponent) },

  // This is a catch-all for URLs with valid locale.
  // The invalid locale use case (e.g. "/ar" or "/ar/..." or "/randomLocale") should be caught by the server when the
  // server is validating the locale in get pre-rendered HTML cloud function.
  { path: "**", loadComponent: () => import('src/app/pages/page-not-found/page-not-found.component').then(m => m.PageNotFoundComponent) },
];
