import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom, isDevMode, provideExperimentalZonelessChangeDetection } from '@angular/core';
import { provideRouter, Router, withEnabledBlockingInitialNavigation } from '@angular/router';

import { provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { provideClientHydration, withEventReplay } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { createErrorHandler, TraceService } from '@sentry/angular';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { routes } from './app.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideExperimentalZonelessChangeDetection(),
    provideRouter(routes, withEnabledBlockingInitialNavigation()),
    provideClientHydration(withEventReplay()),
    importProvidersFrom(ShareButtonsModule.withConfig({
      prop: {
        twitter: {
          icon: ['fab', 'x-twitter'],
          color: '#1E3050',
        }
      }
    })),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    {
      provide: ErrorHandler,
      useValue: createErrorHandler(),
    },
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [TraceService],
      multi: true,
    },
    importProvidersFrom(
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: !isDevMode(),
        // Register the ServiceWorker as soon as the application is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000'
      })),
  ]
};
