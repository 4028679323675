import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PanomnomImageComponent } from 'src/app/panomnom-image/panomnom-image.component';
import { SvgComponent } from 'src/app/svg/svg.component';
import { CONSTANTS } from '../../../../shared/constants';
import { DataService } from '../../../app/data.service';
import { FeedbackBubbleVisibility, FeedbackService } from './../feedback.service';

@Component({
  standalone: true,
  selector: 'app-feedback-bubble',
  templateUrl: './feedback-bubble.component.html',
  styleUrls: ['./feedback-bubble.component.scss'],
  imports: [
    CommonModule,
    FormsModule,

    PanomnomImageComponent,
    SvgComponent,
  ],
})
export class FeedbackBubbleComponent implements OnInit {
  constants = CONSTANTS;

  feedbackValue = '';
  isOpen = false;
  isFeedbackSubmitted = false;
  isVisible = true;

  constructor(private dataService: DataService, private feedbackService: FeedbackService) { }

  ngOnInit(): void {
    this.feedbackService.getFeedbackBubbleVisibilityState().subscribe(state => {
      this.isVisible = (state === FeedbackBubbleVisibility.VISIBLE);
    });
  }

  onToggle() {
    this.isOpen = !this.isOpen;

    if (this.isOpen) {
      this.resetForm();
    }
  }

  onSubmitFeedback() {
    if (this.isFeedbackSubmitted || this.feedbackValue.trim().length === 0) {
      return;
    }

    this.dataService.postFeedback(this.feedbackValue);
    this.isFeedbackSubmitted = true;
  }

  private resetForm() {
    this.feedbackValue = '';
    this.isFeedbackSubmitted = false;
  }
}
