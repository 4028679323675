import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { FeedbackBubbleComponent } from 'src/app/feedback/feedback-bubble/feedback-bubble.component';

declare global {
  interface Window {
    adsbygoogle: any,
  }

  interface Document {
    description: string,
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    FeedbackBubbleComponent,
    RouterModule,
  ],
})
export class AppComponent {
  constructor(iconLibrary: FaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    iconLibrary.addIcons(faXTwitter);
  }
}
